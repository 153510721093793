import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Link from 'next/link';

export function LoginButton() {
    return (
        <Link href="/login" className="border rounded-2xl p-2 flex flex-row items-center gap-2">
            <AccountCircleIcon />
            <span>connexion</span>
        </Link>
    );
}
