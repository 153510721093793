import React from 'react';

type prop = {
    label: string;
};

export default function Badge({ label }: prop): React.ReactNode {
    return (
        <div className="absolute uppercase top-3 left-4 bg-brand px-2 font-medium text-xs text-gray rounded-md shadow-2xl">
            {label}
        </div>
    );
}
