'use client';
import { RefObject, useEffect, useState } from 'react';

export default function useIsIntersecting(ref: RefObject<HTMLElement>) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIsIntersecting(entry.isIntersecting);
            console.log(entry);
        });
        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => observer.disconnect();
    }, [ref]);

    return { isIntersecting };
}
