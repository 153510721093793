import React from 'react';
import { ProductEntity } from '@/model/product';
import Product from '@/components/Product/Product';

type prop = {
    products: ProductEntity[];
};
export default function ProductList({ products }: prop): React.ReactNode {
    return (
        <div className="product-list flex flex-row flex-wrap justify-center items-center mt-8 gap-2 md:gap-8">
            {products.map((product) => (
                <Product key={product.id} product={product} />
            ))}
        </div>
    );
}
