export interface ApiResponse<T> {
    data: T;
    meta: Meta;
}

export interface ResponseObject<T> {
    id: number;
    attributes: T;
}

export interface Media {
    data: MediaObject;
}

export interface Medias {
    data: MediaObject[];
}

export interface MediaObject {
    id: number;
    attributes: MediaAttributes;
}

export interface MediaAttributes {
    name: string;
    alternativeText: any;
    caption: any;
    width: number;
    height: number;
    formats: MediaFormats;
    hash: string;
    ext: string;
    mime: string;
    size: number;
    url: string;
    previewUrl: any;
    provider: string;
    provider_metadata: any;
    createdAt: string;
    updatedAt: string;
}

export interface MediaFormats {
    large: MediaFormat;
    small: MediaFormat;
    medium: MediaFormat;
    thumbnail: MediaFormat;
}

export interface MediaFormat {
    ext: string;
    url: string;
    hash: string;
    mime: string;
    name: string;
    path: any;
    size: number;
    width: number;
    height: number;
}

export interface EmptyObject<T> {
    data: T;
}

export interface Meta {
    pagination: Pagination;
}

export interface Pagination {
    page: number;
    pageSize: number;
    pageCount: number;
    total: number;
}

export const firstPage: Pagination = {
    page: 1,
    pageSize: process.env.PAGE_SIZE == undefined ? 25 : +process.env.PAGE_SIZE,
    pageCount: 1,
    total: 1
};

const headers = {
    Authorization: `bearer ${process.env.AUTH_TOKEN}`,
    'content-type': 'application/json'
};

export async function Get(endpoint: string) {
    const url = `${process.env.API_URL}${endpoint}`;

    const response = await fetch(url, {
        cache: 'no-store',
        headers: headers
    });

    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }

    return await response.json();
}

export async function Post(endpoint: string, body: {}) {
    const url = `${process.env.API_URL}${endpoint}`;

    const response = await fetch(url, {
        cache: 'no-store',
        headers: headers,
        method: 'POST',
        body: JSON.stringify(body)
    });

    if (!response.ok) {
        throw new Error(`{status: ${response.status},  message: ${response.body}`);
    }

    return response;
}
