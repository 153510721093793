'use client';
import React from 'react';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useRouter } from 'next/navigation';
import { useCartProducts } from '@/hook/cart/context';

function CartWidget() {
    const cartProducts = useCartProducts();
    const router = useRouter();
    return (
        <IconButton
            aria-label="cart"
            className="text-brand-dark"
            onClick={() => router.push('/panier')}>
            <Badge badgeContent={cartProducts.count} color="primary">
                <ShoppingCartIcon />
            </Badge>
        </IconButton>
    );
}

export default CartWidget;
