'use client';
import React from 'react';
import Logo from '@/components/Logo/Logo';
import { useRouter } from 'next/navigation';
import { ArrowBackIosNewOutlined } from '@mui/icons-material';
import { LoginButton } from '../LoginButton/LoginButton';
import CartWidget from '../CartWidget/CartWidget';
import Image from 'next/image';

type prop = {
    showLogo?: boolean;
    showBackButton?: boolean;
    onBackClick?: () => void;
};

export default function AppBar(prop: prop): React.ReactNode {
    const router = useRouter();
    const showLogo: boolean = prop.showLogo ?? false;
    const showBackButton: boolean = prop.showBackButton ?? false;
    const authEnabled = process.env.NEXT_PUBLIC_ENABLE_AUTH == 'true';
    const handleOnClick: () => void = prop.onBackClick ?? router.back;

    const newAppBar = () => {
        return (
            <div className="w-full flex flex-row items-center justify-between p-2 shadow-sm">
                <div id="logo" className="relative w-32 h-16">
                    <Image
                        className="object-cover"
                        src={'/images/small-logo.svg'}
                        alt={'Ginger me Logo'}
                        fill
                    />
                </div>
                <ul className="actions flex flex-row items-center">
                    <CartWidget />
                </ul>
            </div>
        );
    };

    const defaultAppBar = () => {
        return (
            <div className="w-full flex flex-col items-center">
                <div className="w-full flex flex-row items-center justify-between p-4">
                    {showBackButton && (
                        <div className="flex flex-row items-center gap-2" onClick={handleOnClick}>
                            <ArrowBackIosNewOutlined />
                            <span>Retour</span>
                        </div>
                    )}
                    <span></span>
                    <div className="flex flex-row items-center gap-4">
                        <CartWidget />
                        {authEnabled && <LoginButton />}
                    </div>
                </div>
                {showLogo && (
                    <div className="w-full mt-8 text-black flex flex-row items-center justify-center">
                        <Logo />
                    </div>
                )}
            </div>
        );
    };
    return newAppBar();
}
