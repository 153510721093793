'use client';
import ProductList from '@/components/ProductList/ProductList';
import { findProducts } from '@/service/product';
import AppBar from '@/components/AppBar/AppBar';
import React, { useEffect, useRef, useState } from 'react';
import useIsIntersecting from '@/hook/useIsIntersecting';
import { ProductEntity } from '@/model/product';
import { firstPage, Pagination } from '@/service/api';
import CircularProgress from '@mui/material/CircularProgress';

export default function Home() {
    const myRef = useRef(null);
    const { isIntersecting } = useIsIntersecting(myRef);
    const [products, setProducts] = useState<ProductEntity[]>([]);
    const [pagination, setPagination] = useState<Pagination>(firstPage);
    const [showLoader, setShowLoader] = useState<boolean>(false);

    const fetchProducts = () => {
        setShowLoader(true);
        findProducts(pagination)
            .then((resp) => {
                setProducts([...products, ...resp.products]);
                setPagination({ ...resp.pagination, page: resp.pagination.page + 1 });
            })
            .catch((e) => console.error('fail to find products', e))
            .finally(() => setShowLoader(false));
    };

    useEffect(() => {
        if (isIntersecting) {
            fetchProducts();
        }
    }, [isIntersecting]);

    return (
        <>
            <AppBar />
            <div className="mb-8"></div>
            <main className="flex flex-col items-center justify-between">
                <ProductList products={products} />
                <span ref={myRef}></span>
                <CircularProgress className="mt-16" color="info" hidden={!showLoader} />
            </main>
            <div className="mb-8"></div>
        </>
    );
}
