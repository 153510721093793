import React from 'react';
import Image from 'next/image';
import { ProductEntity } from '@/model/product';
import Link from 'next/link';
import Badge from '@/app/(pages)/product/components/Badge/Badge';

type prop = {
    product: ProductEntity;
};
export default function Product({ product }: prop): React.ReactNode {
    const isNew = (): boolean => {
        const threshold = 30;
        const productDate = new Date(Date.parse(product.publishedAt));
        const now = new Date();
        const diffInDays = Math.round((now.getTime() - productDate.getTime()) / (1000 * 3600 * 24));

        return !isSold() && diffInDays < threshold;
    };

    const isSold = (): boolean => {
        return product.sold;
    };

    return (
        <Link
            href={`product/${product.id}`}
            className="product flex flex-col items-start mb-4 gap-3 w-[11rem] md:w-[12rem] rounded-2xl">
            <div className="cover relative w-full h-[190px] md:h-[220px] overflow-hidden rounded-2xl">
                <Image
                    className="rounded-2xl object-cover transition ease-in-out delay-75 duration-1000 hover:scale-125 hover:-rotate-12"
                    fill
                    src={product.cover}
                    alt={`product ${product.title} cover picture`}
                    quality={100}
                />
                {isSold() && <Badge label="vendu" />}
                {isNew() && <Badge label="new" />}
            </div>
            <h2 className={'text-sm capitalize'}>{product.title}</h2>
            <div className="price text-lg font-black text-gray flex flex-row items-center">
                €{product.price}
            </div>
        </Link>
    );
}
