import React from 'react';
import Link from 'next/link';
import Image from 'next/image';

export default function Logo(): React.ReactNode {
    return (
        <Link href={'/'} className="relative w-full md:w-[500px] h-[250px]">
            <Image src={'/images/Logo.svg'} alt={'Ginger me Logo'} fill />
        </Link>
    );
}
